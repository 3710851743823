.paypal-btn {
	background-color: #0070ba;
	border: none;
	align-items: center;
	border-radius: 50px;
	height: 48px;
	font-size: 16px;
	width: 230px;
	color: #ffffff;
}

.paypal-icon {
	display: inline;
	font-size: 20px;
	margin-right: 9px;
}

.paypal-btn:hover {
	background-color: #018eec;
}

.align-btn {
	justify-content: center;
}
