/**
 * = Avatars
 */

.avatar + .avatar-content {
	display: inline-block;
	margin-left: 0.75rem;
}

.avatar-link {
	img {
		width: 4rem;
		height: 4rem;
	}
}

.author-image {
	img {
		width: 3rem;
		height: 3rem;
	}
}

.user-avatar {
	height: $user-avatar-height;
	width: $user-avatar-width;
	min-height: $user-avatar-height;
	min-width: $user-avatar-width;
	color: $white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: $font-weight-bold;
	@include border-radius($circle-radius);
	&.xs-avatar {
		height: $user-avatar-height-xs;
		width: $user-avatar-width-xs;
		img {
			height: $user-avatar-height-xs;
			width: $user-avatar-width-xs;
		}
	}

	.md-avatar {
		height: 2rem;
		width: 2rem;
		min-height: 2rem;
		min-width: 2rem;
	}

	&.lg-avatar {
		height: $user-avatar-height-lg;
		width: $user-avatar-width-lg;
		min-height: $user-avatar-height-lg;
		min-width: $user-avatar-width-lg;
		font-size: $font-size-xs;

		@include media-breakpoint-down(lg) {
			height: 2rem;
			width: 2rem;
			min-height: 2rem;
			min-width: 2rem;
		}
	}

	&.xl-avatar {
		height: $user-avatar-height-xl;
		width: $user-avatar-width-xl;
		min-height: $user-avatar-height-xl;
		min-width: $user-avatar-width-xl;
	}

	&.large-avatar {
		border: 2px solid $white;
		height: $user-avatar-height-xxl;
		width: $user-avatar-width-xxl;
		min-height: $user-avatar-height-xxl;
		min-width: $user-avatar-width-xxl;
	}
}

// Overlapped avatars

.avatar-group {
	.avatar {
		position: relative;
		z-index: 2;
		border: 2px solid $card-bg;

		&:hover {
			z-index: 3;
		}
	}

	.avatar + .avatar {
		margin-left: -1rem;
	}
}
