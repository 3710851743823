.div-date-range{
    border-radius: 10px;
    justify-content: start;
    height: 45px;
    display: flex !important;
    margin-top: 25px;
    margin-bottom: 18px;
    
}

.btn-date{
    background-color: white;
    border-color: #009FE3;
    color: #009FE3;
    margin-right: 15px;
}

.btn-date:hover{
    background-color: #eee9fc;
    color: #009FE3;
    
}

.btn-date:focus{
    background-color: #eee9fc;
    color: #009FE3;
    
}

.support-bann {
	background-image: url('../../assets/img/banners/support_1.png');
	background-repeat: no-repeat;
}

.tutorial-bann {
	background-image: url('../../assets/img/banners/tutorial.svg');
	background-repeat: no-repeat;
}

.yellow-bann {
	background-image: url('../../assets/img/banners/Amarillo.png');
	background-repeat: no-repeat;
}

.product-bann {
	background-image: url('../../assets/img/banners/product.png');
	background-repeat: no-repeat;
}

.playstore-btn {
	background-image: url('../../assets/img/technologies/playStore.png');
	background-repeat: no-repeat;
	height: 64px;
	width: 193px;
	border-radius: 18px;
}

.appstore-btn {
	background-image: url('../../assets/img/technologies/appStore.png');
	background-repeat: no-repeat;
	height: 64px;
	width: 193px;
	border-radius: 18px;
}

.welcome-banner {
	background-image: url('../../assets/img/illustrations/living.png');
	background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 250px;
    border-radius: 28px;
    border: none;
}

.simple-card-widget{
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

@media (max-width: 600px) {
	.support-bann {
		background-image: url('../../assets/img/banners/support_xs.svg');
		background-repeat: no-repeat;
	}

	.tutorial-bann {
		background-image: url('../../assets/img/banners/tutorial.svg');
		background-repeat: no-repeat;
	}

	.product-bann {
		background-image: url('../../assets/img/banners/product_1.svg');
		background-repeat: no-repeat;
	}

	.rw-tutorial {
		margin-top: 12%;
		justify-content: center;
	}

	.rw-supp {
		margin-top: 12%;
		justify-content: center;
	}

	.btn-create-supp {
		margin-top: 3%;
	}
}

