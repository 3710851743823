.normal-text {
    color: gray;
}
.error-text {
    color: #FD153F;
}
.success-text {
    color: #599A17;
}
.character-count-text {
    position: absolute;
    right: 0;
    padding-right: 16px;
}