/**
 * = Accordions
 */

 .accordion-button {
    text-align: left;

    .btn-check:focus + .btn, .btn:focus {
        outline: 0;
        box-shadow: none;
    }
    
    &:hover{
        background-color: $gray-200;
    }
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
    .accordion-button {
        background-color: transparent;
        &:not(.collapsed) {
            color: $secondary;
        }
        &:focus {
            border-color: $gray-300;
            box-shadow: none;
        }
    }
    .accordion-collapse {
        border: 0;
		border: 0.0625rem solid rgba(33, 73, 202, 0.125);
	
    }
}




// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.
/* .accordion-body {
	background-color: #fff;
}


.accordion-flush {
	.accordion-button {
		background-color: transparent;
		&:not(.collapsed) {
			color: $secondary;
		}
		&:focus {
			border-color: $secondary;
			box-shadow: none;
		}
	}
	.accordion-collapse {
		border: 0;
		background-color: $secondary;
	}
}
 */