

.collapse {
	background-color: rgba(0, 0, 0, 0.04);
}

/* SimpleAnnotations */
#simpleHotspot {
	background: rgb(255, 255, 255);
	border-radius: 100px;
	border: 0px;
	box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px;
	cursor: pointer;
	height: 24px;
	width: 24px;
}

/* Model viewer Slider */
#slider {
	width: 100%;
	text-align: center;
	overflow: hidden;
	position: absolute;
	bottom: 16px;
}

#slides {
	display: flex;
	overflow-x: auto;
	scroll-snap-type: x mandatory;
	scroll-behavior: smooth;
	-webkit-overflow-scrolling: touch;
}

#slide {
	scroll-snap-align: start;
	flex-shrink: 0;
	width: 80px;
	height: 80px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	background-color: rgb(235, 235, 235);
	margin-right: 10px;
	border-radius: 10px;
	border: none;
	display: flex;
}

.btn-code {
	position: absolute;
	right: 0;
	margin-right: 22px;
	height: 25px;
	margin-top: 5px;
	background-color: #009FE3;
	border-radius: 10px;
	font-size: 14px;
	border: none;
	color: #fff;
	font-weight: 600;
}

.btn-code:hover {
	background-color: rgb(255, 255, 255);
	color: #009FE3;
}

.backdrop-loader {
	position: absolute;
	background-color: rgba(31, 31, 31, 0.582);
	width: 100vw;
	height: 100vh;
	z-index: 9998;
	display: flex;
	align-items: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	justify-items: center;
	justify-content: center;
	align-items: center;
}

.centered-label {
	color: #000;
	align-self: flex-end;
	text-align: end;
}

.btn-create {
	margin-right: 4%;
	margin-top: 2%;
}

.btn-create-supp {
	margin-right: 4%;
	margin-top: 2%;
}

.btn-secondary {
	background-color: #009FE3;
	border: none;
	color: #fff;
	width: 185px ;
}

.card-md {
	background-color: transparent;
	border-color: transparent;
	min-height: 220px;
	box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
}

.card-xs {
	background-color: transparent;
	border-color: transparent;
	min-height: 200px;
	box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
}

.card-md-white {
	align-items: center;
	background-color: #fff;
	border-color: transparent;
	min-height: 220px;
	box-shadow: 2px 2px rgba(0, 0, 0, 0.1);
}

.btn-170w {
	width: 170px;
}

.rw-create {
	justify-content: flex-end;
	margin-right: 8%;
	margin-top: 5%;
}

.rw-supp {
	justify-content: flex-end;
	margin-right: 8%;
	margin-top: 5%;
}

.rw-tutorial {
	justify-content: start;
	margin-left: 6%;
	margin-top: 5%;
}

.rw-yellow-banner {
	justify-content: start;
	margin-left: 2%;
	margin-top: 2%;
}

.up-plan {
	color: #fff;
	font-size: 24px;
}

.loader-spinner {
	z-index: 9999;
	color: #fff;
}


.icon-upg {
	color: #fff;
}

.txt-tutorial {
	color: #fff;
	align-self: flex-start;
	text-align: start;
	font-weight: 600;
}

.txt-banner-dark {
	color: #07085B;
	align-self: flex-start;
	text-align: start;
	font-weight: 800;
}

.txt-banner-support {
	color: #07085B;
	align-self: flex-start;
	text-align: end;
	font-weight: 800;
}

.dv-nav-btn {
	margin-right: 30px;
}

.copilot-btn{
 height: 35px;
 box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.12) 0px 8px 32px;
 
}

@media (max-width: 600px) {
	.support-bann {
		background-image: url('../assets/img/banners/soporte.png');
		background-repeat: no-repeat;
	}

	.tutorial-bann {
		background-image: url('../assets/img/banners/tutorial.svg');
		background-repeat: no-repeat;
	}

	.product-bann {
		background-image: url('../assets/img/banners/product_1.svg');
		background-repeat: no-repeat;
	}

	.rw-tutorial {
		margin-top: 12%;
		justify-content: center;
	}

	.rw-supp {
		margin-top: 12%;
		justify-content: center;
	}

	.btn-create-supp {
		margin-top: 3%;
	}
}

.dropdown-size {
	max-height: 300px;
	overflow-y: scroll;
	scrollbar-width: thin;
}

@media screen and (max-width: 1570px) and (min-width: 1199px) {
	.icon-upg {
		display: none;
	}
}

@media screen and (max-width: 940px) and (min-width: 767px) {
	.icon-upg {
		display: none;
	}
}
