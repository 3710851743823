/**
 * = Close
 */

.close {
	@if $enable-transitions {
		transition: $transition-base;
		border: none;
		background-color: transparent;
	}
	& > span:not(.sr-only) {
		display: block;
		height: 1.55rem;
		width: 1.55rem;
		background-color: $close-bg !important;
		color: $close-color !important;
		line-height: 17px;
		border-radius: 50%;
		font-size: 2rem;
		@if $enable-transitions {
			transition: $transition-base;
		}
	}

	&:hover,
	&:focus {
		background-color: $close-hover-bg;
		color: $close-hover-color;
		outline: none;

		span:not(.sr-only) {
			background-color: $close-hover-bg;
		}
	}
}
