// Contextual backgrounds
@mixin bg-variant($parent, $color) {
	#{$parent} {
		background-color: $color !important;
	}
	a#{$parent},
	button#{$parent} {
		@include hover-focus {
			background-color: darken($color, 10%) !important;
		}
	}
}

@mixin bg-gradient-variant($parent, $color) {
	#{$parent} {
		background: linear-gradient(
			87deg,
			$color 0,
			saturate($color, 10%) 100%
		) !important;
	}
}

@mixin background-image($background-repeat, $background-size) {
	background-repeat: $background-repeat;
	background-position: top center;
	background-size: $background-size;
}
