body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.rdt_TableCol:first-child{
    border-radius: 15px 0 0 0 !important;
}


.rdt_TableCol:last-child{
    border-radius: 0 15px 0 0 !important;
}

.rdt_TableCol{
    background-color: #061741 !important;
}

 .rdt_TableCol_Sortable{
    color: #fff !important;
}

.rdt_TableCol_Sortable:hover , .rdt_TableCol_Sortable::selection{
    color: #009FE3 !important;
}


.rdrMonthAndYearPickers select{
  background-color: #fff !important;
}

.rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge {
	background: none;
	background-color: #009FE3 !important;
}

.btn-opc-base {
    background-color: #f0f0f093;
	border-radius: 50px;
	border: none;
	color: white;
	height: 38px;
	width: 38px;
	font-size: 12px;
	cursor: pointer;
	align-self: center;
	margin-right: 0.5em;
}

.edit:hover {
    background-color: #f7c2213d;
	color: #f7c221;
}

.delete:hover {
	background-color: #fd7c6238;
}

.code:hover {
	background-color: #009FE331;
}

.btn-opc-base:focus {
	background-color: #f0f0f093;
}


/*----------------------------------- */

.icon-edit {
	color: #f7c221;
	font-size: 19px;
}

.icon-success {
	color: #4bb543;
	font-size: 22px;
}

.icon-wrong {
	color: #fb6d51;
	font-size: 22px;
}

.icon-info {
	color: #009FE3;
	font-size: 19px;
}

.icon-qr {
	color: #6da032;
	font-size: 19px;
}

.icon-code {
	color: #009FE3;
	font-size: 17px;
	
}

.icon-delete {
	color: #fb6d51;
	font-size: 19px;
}



.icon-unlink {
	color: #009FE3;
	font-size: 16px;
}

.butn-qr:hover {
	background-color: #67992e3b;
}
