html {
	height: 100%;
}

#sis {
	cursor: pointer;
}

.wrapper {
	width: 100%;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-style: dashed;
	border-color: #009FE3;
	min-width: 280px;
	cursor: pointer !important;

	.file-upload {
		height: 100%;
		width: 100%;
		border-radius: 1px;
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 4px solid #ffffff;
		overflow: hidden;
		background-size: 100% 200%;
		transition: all 1s;
		color: #009FE3;
		font-size: 80px;
		cursor: pointer !important;


		input[type='file'] {
			height: 100%;
			width:  100%;
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			cursor: pointer !important;
		}

		input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
			cursor: pointer; 
		}

		&:hover {
			background-position: 0 -100%;
			color: #009FE3;
		}
	}
}
