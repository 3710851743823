/**
 * = Text utility classes
 */

.text-underline {
	text-decoration: underline !important;
}
.text-through {
	text-decoration: line-through !important;
}

// Display responsive styles
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		.display-1-#{$breakpoint} {
			font-size: $display1-size;
		}
		.display-2-#{$breakpoint} {
			font-size: $display2-size;
		}
		.display-3-#{$breakpoint} {
			font-size: $display3-size;
		}
		.display-4-#{$breakpoint} {
			font-size: $display4-size;
		}
	}
}

// Line heights
// from lh-100 to lh-300
@for $nr from 0 to 21 {
	.lh-#{100 + $nr*10} {
		line-height: #{1 + $nr*0.1};
	}
}

// Letter spacings
.ls-1 {
	letter-spacing: 0.0625rem;
}
.ls-2 {
	letter-spacing: 0.09375rem;
}
.ls-3 {
	letter-spacing: 0.125rem;
}

// Responsive alignment
@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);

		.text#{$infix}-left {
			text-align: left !important;
		}
		.text#{$infix}-right {
			text-align: right !important;
		}
		.text#{$infix}-center {
			text-align: center !important;
		}
	}
}

// Transformation
.text-body {
	color: $body-color !important;
}

.text-black-50 {
	color: rgba($black, 0.5) !important;
}
.text-white-50 {
	color: rgba($white, 0.5) !important;
}

.list-style-none {
	margin: 0px;
	padding: 0px;
	list-style: none;
}
