.preloader {
	display: flex;
	align-items: center;
	height: 100vh;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
	@include transition(height 0.3s);

	.loader-element {
		@include transition(spin 0.3s linear infinite);
		-webkit-animation: spin 3s linear infinite;
		-moz-animation: spin 3s linear infinite;
		animation: spin 3s linear infinite;
	}

	&.show {
		height: 0;

		.loader-element {
			display: none !important;
		}
	}
}

@-moz-keyframes spin {
	100% {
		-moz-transform: rotate(360deg);
	}
}
@-webkit-keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
	}
}
@keyframes spin {
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
