.zindex-1 {
	z-index: -1;
}

.zindex1 {
	z-index: 1;
}

.transform {
	transform: translate(-150px, 37px) !important;
}
