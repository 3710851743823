/**
 * = Pricing cards
 */

.pricing-card {
	.card-header {
		background: transparent;
		z-index: 2;
	}

	.card-body {
		z-index: 2;
	}

	.pricing-value {
		width: 150px;
		height: 150px;
		border-radius: 50%;
		border-width: $border-width-xl;
		border-style: double;
		margin: 0 auto;

		span {
			line-height: 140px;
		}
	}

	.list-group-item {
		background: transparent;
		border: none;
		padding: 0.375rem 0.125rem;
		i {
			display: inline-block;
			vertical-align: middle;
			width: 35px;
		}
	}
	@include media-breakpoint-down(md) {
		margin-top: 1rem;
	}
}
